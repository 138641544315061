import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";
import { useLanguage } from "../LanguageContext";
import { useEffect } from "react";

const Layout = () => {
  const { language } = useLanguage();

  return (
    <div className={language === "en" ? "" : "arabic"}>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
